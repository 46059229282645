<template>
    <div class="price-negotiations">

        <div class="page-title d-flex py-2 justify-content-between">

            <h1 class="h4 mb-0 pr-5">Перечень согласования цен</h1>

            <div class="flex-grow-1">
                <router-link to="/price-negotiations-report">
                    <h1 class="h4 text-primary">Отчет</h1>
                </router-link>
            </div>

            <div class="d-flex align-items-center">
                <BButton
                    class="ml-3"
                    variant="outline-primary"
                    v-b-modal.import>Импортировать
                </BButton>
                <BButton
                    v-if="user && user.is_create_price_negotiation"
                    class="ml-3"
                    variant="primary"
                    to="/create-price-negotiation"
                >Создать
                </BButton>
            </div>

        </div>

        <TableFilter
            :columns="positionsFields"
            @update-selected-columns="columns = $event"
            @search="onSearch"
            @clear="clearFilterData"
            @open-filter-modal="$root.$emit('bv::toggle::collapse', 'filter')"
        />

        <DefaultTable
            :data="filteredNegotiations"
            :fields="positionsFieldsFiltered"
            :filter="filter"
            :loading="loading"
            rowLink="/price-negotiations"
            emptyText="Перечень согласования цен пуст"
            downloadFor="price-negotiations"
            :noLocalSorting="true"
            @sort-change="sorting"
            @pagination-change="paginationChange"
            @per-page-change="perPageChange"
        />

        <FilterSidebar
            id="filter"
            v-model="filterData"
            @apply="updateDataWithFilters"
        />

        <b-modal
            id="import"
            centered title="Импорт перечня согласования цен">

            <div class="p-2 bg-light">

                <div class="font-weight-bold text-muted">Файл для импорта <span class="text-danger">*</span></div>

                <b-form-file
                    v-model="importFile"
                    class="mt-1"
                    plain />

            </div>

            <template #modal-footer="{ hide }">
                <b-button variant="outline-primary" @click="hide()">
                    Отмена
                </b-button>
                <b-button
                    variant="primary"
                    @click="sendImport">
                    Импортировать
                </b-button>
            </template>
        </b-modal>

        <b-modal
            id="import-success"
            centered title="Импорт завершён">

            <div class="p-2 bg-light">

                <p>Импортирование файла «{{ importFile ? importFile.name : '' }}» завершено</p>

            </div>

            <template #modal-footer="{ hide }">
                <b-button
                    variant="primary"
                    @click="hide()">
                    Хорошо
                </b-button>
            </template>
        </b-modal>

    </div>
</template>

<script>

import TableFilter from "@/components/TableFilter"
import DefaultTable from "@/components/Tables/Default"
import FilterSidebar from "@/components/FilterSidebar"
import { mapGetters, mapState } from 'vuex'

function initialFilterData() {
    return {
        number: '',
        date: ['', ''],
        type: '',
        organization_status: '',
        object_name: '',
        order_number: '',
        provider_name: ''
    }
}

export default {
    name: "PriceNegotiationList",
    components: {
        TableFilter,
        DefaultTable,
        FilterSidebar
    },
    computed: {
        ...mapState({
            negotiations: state => state.priceNegotiations.negotiations
        }),
        positionsFieldsFiltered () {
            let res = []
            this.positionsFields.forEach(item => {
                if (this.columns.filter(col => col === item.label).length) {
                    res.push(item)
                }
            })
            return res.length > 0 ? res : this.positionsFields
        },
        ...mapGetters( {
            isProvider: 'isProvider',
            isContractor: 'isContractor'
        } ),
        filteredNegotiations() {
            if ( this.isContractor ) {
                const data = this.negotiations?.data?.filter( el => (
                    el.type !== 'contract_home_method'
                ) )
                return {
                    ...this.negotiations,
                    data
                }
            }
            return this.negotiations
        },
        user() {
            return this.$store.state?.account?.user;
        }
    },
    data: () => ({
        positionsFields: [
            {
                key: 'number',
                sortable: true,
                label: '№ запроса',
                full: 'Номер запроса',
                openDefault: true
            },
            {
                key: 'type',
                sortable: true,
                label: 'Тип запроса',
                full: 'Тип запроса',
                openDefault: true
            },
            {
                key: 'date',
                sortable: true,
                label: 'Дата запроса',
                full: 'Дата запроса',
                openDefault: true
            },
            {
                key: 'organization_status',
                sortable: true,
                label: 'Статус',
                full: 'Статус',
                openDefault: true
            },
            // {
            //     key: 'order_customer_object',
            //     sortable: true,
            //     label: 'Объект',
            //     full: 'Объект',
            //     openDefault: true
            // },
            // {
            //     key: 'order_customer_sub_object',
            //     sortable: true,
            //     label: 'Подобъект',
            //     full: 'Подобъект',
            //     openDefault: true
            // },
            {
                key: 'provider',
                nesting: 'order_provider',
                sortable: true,
                label: 'Поставщик',
                full: 'Поставщик',
                openDefault: true
            },
            {
                key: 'contractor',
                nesting: 'order_contractor',
                sortable: true,
                label: 'Подрядчик',
                full: 'Подрядчик',
                openDefault: true
            },
            {
                key: 'order_customer_organization_id',
                sortable: true,
                label: 'Филиал',
                full: 'Филиал',
                openDefault: true
            },
            {
                key: 'order_number_generated',
                sortable: true,
                label: 'Заказ на поставку',
                full: 'Заказ на поставку',
                openDefault: true
            },
            {
                key: 'work_agreement',
                sortable: true,
                label: '№ договора',
                full: '№ договора',
                openDefault: true
            },
            {
                key: 'responsible_full_name',
                sortable: true,
                label: 'Ответственный',
                full: 'Ответственный',
                openDefault: false
            },
            {
                key: 'responsible_phone',
                sortable: true,
                label: 'Телефон',
                full: 'Телефон',
                openDefault: false
            },
            {
                key: 'file_urls',
                sortable: false,
                label: 'Приложение',
                full: 'Приложение',
                openDefault: true
            },
            {
                key: 'comment',
                sortable: true,
                label: 'Комм.',
                full: 'Комментарий',
                openDefault: true
            },
            {
                key: 'creation_date',
                sortable: true,
                label: 'Дата создания',
                full: 'Дата создания',
                openDefault: true
            }
        ],
        importFile: null,
        loading: true,
        columns: [],
        filter: '',
        filterData: initialFilterData(),
        breadcrumbs: [
            { title: 'Главная', link: '/' },
            { title: 'Перечень согласования цен' }
        ],
        sortingData: {
            sort_field: '',
            sort_order: '',
            page: '',
            per_page: 25
        }
    }),
    methods: {
        async getData () {
            this.loading = true
            await this.$store.dispatch('getNegotiations')
            this.loading = false
        },
        sendImport () {
            if (this.importFile !== null) {
                this.$bvModal.show('import-success')
                this.$bvModal.hide('import')
            } else {
                this.$bvToast.toast('Вам необходимо загрузить файл формата XML', {
                    title: 'Вы не прикрепили файл для импорта',
                    toaster: 'b-toaster-top-right',
                    solid: true,
                    appendToast: false,
                    variant: 'danger'
                })
            }
        },
        clearFilterData () {
            this.filterData = initialFilterData()
            this.filter = ''
            this.getData()
        },
        updateDataWithFilters () {
            const params = {
                ...this.filterData,
                ...this.sortingData
            }
            this.loading = true
            this.$store.dispatch('getNegotiations', params).then(() => {
                this.loading = false
            })
        },
        sorting (event) {
            this.sortingData.sort_field = event.sortBy,
            this.sortingData.sort_order = event.sortDesc ? 'desc' : 'asc'
            this.updateDataWithFilters()
        },
        paginationChange (page) {
            this.sortingData.page = page
            this.updateDataWithFilters()
        },
        perPageChange (perPage) {
            this.sortingData.page = ''
            this.sortingData.per_page = perPage
            this.updateDataWithFilters()
        },
        async onSearch( event ) {
            this.filter = event
            this.filterData.number = event
            
            this.updateDataWithFilters()
        }
    },
    mounted () {
        this.getData()
            .then( () => {
                if ( this.isProvider )
                    this.positionsFields = this.positionsFields.filter( el => el.key !== 'contractor' )
            } )
        this.positionsFields.forEach(item => {
            if (item.openDefault) {
                this.columns.push(item.label)
            }
        })
    }
}
</script>

<style
    lang="sass"
    scoped
>

</style>
